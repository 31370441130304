import React from 'react';
import styled from 'styled-components';
import { AppContext } from '../../App';
import MenuIcon from '@mui/icons-material/Menu';
import ContactButton from './ContactButton';

const Header = () => {

    const { workshopsRef, scrollToSection, filters, setFilters } = React.useContext(AppContext);

    const [menuToggled, toggleMenu] = React.useState(false);

    const activateFilterAndScrollToWorkshops = (filterToActivate) => {
        setFilters(filterToActivate);
        scrollToSection(workshopsRef);
        toggleMenu(false);
    }

    const rendering = () => {
        return (
            <HeaderSC>
                <div className='header-menu'>
                    <div className='header-menu-item'>Home</div>
                    <div className='header-menu-item' onClick={() => activateFilterAndScrollToWorkshops('odoo')}>Odoo Workshops</div>
                    <div className='header-menu-item' onClick={() => activateFilterAndScrollToWorkshops('powerbi')}>Power BI Workshops</div>
                    <div className='header-menu-item'><ContactButton /></div>
                </div>
            </HeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const HeaderSC = styled.div`
    .header-menu {
        margin-right : 20px;
        display : flex;
        align-items : center;
        justify-content : flex-end;
        gap : 20px;
    }

`

export default Header