import React from 'react';
import styled from 'styled-components';
import { AppContext } from '../../App';
import Header from '../header/Header';

const Hero = () => {

    const { workshopsRef, scrollToSection } = React.useContext(AppContext);

    const rendering = () => {
        return (
            <HeroSC style={{backgroundImage : 'url(/brightmindbackground.png)'}}>
                <Header />
                <div className='hero-image' > 
                    <div className='hero-text'>
                        <h1>BrightMinds</h1>
                        <p>Get trained by experienced professionals</p>
                        <button
                            onClick={() => scrollToSection(workshopsRef)}
                        >Discover our courses</button>

                    </div>
                    <img src='image-no-bg.png'/>
                </div>
            </HeroSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const HeroSC = styled.div`
    min-height : 100vh;
    min-width : 100vw;
    max-width : 100vw;
    max-height : 100vh;
    

    .hero-image {
        min-width : 100%;
        max-width : 100%;
        min-height : 600px;
        background-size : cover;
        background-position : center;
        position : absolute;

        &>img {
            max-width : 600px; // Increased from 200px
            max-height : 600px; // Increased from 200px
            min-height : 600px;
            position : absolute;
            top : 60%;
            right : -10%;
            transform : translate(-50%, -50%);
        }
    }

    .hero-text {
        border : 5px solid white; 
        max-width : 550px;
        padding : 35px;
        position : absolute;
        top : 20%;
        left : 10%;
        display : flex;
        flex-direction : column;

        h1 {
            font-size : 3.8em;
            line-height : 0;
            color : #2678D6;
            margin-bottom : 10px;
        }

        p {
            font-size : 1.8em;
            font-weight : 500;
        }

        button {
            background-color : #2678D6;
            border-radius : 15px;
            padding : 15px;
            color : white;
            border : 0;
            font-size : 1.em;
            font-family : inherit;
            max-width: 200px;
            font-weight : 600;
        }
    }


`

export default Hero