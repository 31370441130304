import React from 'react';
import styled from 'styled-components';
import { WorkshopsContext } from './Workshops';
import { MainContentContext } from '../mainContent/MainContent';

const Filtering = () => {

    const { filters, setFilters, trainings } = React.useContext(WorkshopsContext);

    const uniqueCategories = [...new Set(trainings.map(training => training.customProperties.category))];

    const rendering = () => {
        return (
            <FilteringSC>
                <div style={{ display : 'flex', flexDirection : 'column'}}>
                    <h1>Discover our trainings</h1>
                    <div style={{ display : 'flex'}} className='filter-items'>
                        <p style={{textDecoration : filters.length === uniqueCategories.length && 'underline'}} onClick={() => setFilters(uniqueCategories)}>All workshops</p>
                        {uniqueCategories.map((category) => {
                            return (
                                <p 
                                    key={category} 
                                    onClick={() => setFilters([category])}
                                    style={{textDecoration : filters.length !== uniqueCategories.length && filters.includes(category) && 'underline'}}    
                                >{category.charAt(0).toUpperCase() + category.slice(1)}</p>
                            );
                        })}
                    </div>
                </div>




            </FilteringSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const FilteringSC = styled.div`
    display : flex; 
    font-weight : bold;
    font-size : 1.2em;
    gap : 20px;
    margin-bottom : 30px;
    
    .filter-items {
        display : flex;
        gap : 20px;
        align-items : center;
    }
    h1 {
        margin-bottom : 0;
        color: #2678D6;
    }

    &:hover {
        cursor : pointer; 
    }

    @media (max-width: 480px) {
        max-width : 98vw;
        margin : 40px auto;
        gap : 10px;
        justify-content : space-between;

        &>p {
            font-size : 0.95rem;

        }
    }

`

export default Filtering