import logo from './logo.svg';
import Header from './components/header/Header';
import MainContent from './components/mainContent/MainContent';
import { Route, HashRouter as Router, Routes  } from 'react-router-dom';
import IndividualTraining from './components/individualTraining/IndividualTraining';
import React from 'react'; 
import Axios from 'axios'; 
import { backEndAdress } from './variables';
import ConfrimationPage from './components/confirmationPage/ConfirmationPage';

export const AppContext = React.createContext();

function App() {

  const [instances, setInstances] = React.useState([]);   
  const [trainings, setTrainings] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [currentUrl, setCurrentUrl] = React.useState(window.location.href);

  React.useEffect(() => {
    const handleUrlChange = () => {
      setCurrentUrl(window.location.href);
    };

    window.addEventListener('popstate', handleUrlChange);
    window.addEventListener('pushstate', handleUrlChange);

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
      window.removeEventListener('pushstate', handleUrlChange);
    };
  }, []);

  React.useEffect(() => {
      const getTrainings = async () => {
          try {
              const response = await Axios.get(`${backEndAdress}/api/trainings/public` , {
                headers : {
                  'organizationid' : 'org_1gon8J8YzJc0uxhu'
                }
              }); 
              const filteredTrainings = response.data.filter((training) => training.publishedOnWebsite);
              const uniqueCategories = [...new Set(filteredTrainings.map(training => training.customProperties.category))];
              setFilters(uniqueCategories);
              setTrainings(filteredTrainings);
          } catch (error) {
              console.error(error);
          }
      }

      const getInstances = async () => {
        try {
            const response = await Axios.get(`${backEndAdress}/api/instances/public/get-instances-for-website`);
            const instancesNotCustomerLocation = response.data.filter((instance) => instance.locationType === 'our-location' && !instance.cancelled);
            const today = new Date();
            const filterByDateInstances = instancesNotCustomerLocation.filter((instance) => new Date(instance.startDate) >= today);
            setInstances(filterByDateInstances);
        } catch (error) {
            console.log(error);
        } 
    }

      getTrainings();
      getInstances();
  }, []);

  const scrollToSection = (section) => {
    section.current.scrollIntoView({behavior : 'smooth'});
  }

  const workshopsRef = React.useRef(null);

  return (
    <AppContext.Provider value={{
        workshopsRef,
        scrollToSection,
        trainings,
        setTrainings,
        instances,
        setInstances,
        filters,
        setFilters
    }}>
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/training/:id/:instanceId" element={<IndividualTraining />} />
          <Route exact path="/" element={<MainContent />} />
          <Route path='/sign-up-confirmation/:instanceId' element={<ConfrimationPage />} />
        </Routes>
      </Router>
    </div>
    </AppContext.Provider>
  );
}

export default App;
