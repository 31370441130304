import React from 'react';
import styled from 'styled-components';

const PriceAndContact = ({ training }) => {

    const rendering = () => {
        return (
            <PriceAndContactSC>
                {!training.customProperties.onDemand &&
                    <>
                        <h1 style={{fontWeight : 'bold'}}>Price</h1>
                        <p>€ {training.pricePerDay} per person / day</p>
                    </>
                }
                
                <h1 style={{fontWeight : 'bold'}}>Contact</h1>
                <p>Questions about this workshop? </p>
                <p>Contact us at : <span style={{fontWeight : 'bold'}}>brightminds@academy.eu</span> </p>
            </PriceAndContactSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const PriceAndContactSC = styled.div`
    margin-bottom : 50px;
    
    h1 {
        color : #D20A4B;
        font-weight : 400;
        font-size : 1.4rem;
    }

    ul {
        max-width : 100%;

        li {
            max-width : 100%;

            p {
                max-width : 100%;
            }

        }
    }

    p {
        margin : 0;
        color : #54595f;
        font-family : 'Montserrat', sans-serif;
        font-weight : 400;
    }
`

export default PriceAndContact