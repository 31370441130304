import React from 'react';
import styled from 'styled-components';
import Header from '../header/Header';
import Hero from '../hero/Hero';
import SubHeroText from '../sub-hero-text/SubHeroText';
import Workshops from '../workshops/Workshops';
import CTAHero from '../ctaHero/CTAHero';
import Contact from '../contact/Contact';
import Socials from '../socials/Socials';
import GoogleMaps from '../maps/GoogleMaps';
import Footer from '../footer/Footer';
import ReferencesCarousel from '../references/ReferencesCarousel';


const MainContent = () => {

    const rendering = () => {
        return (
            <MainContentSC>
                <Hero />
                <Workshops />
                {/* <ReferencesCarousel />
                <CTAHero />
                <Contact />
                <Socials />
                <GoogleMaps />
                <Footer /> */}
            </MainContentSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MainContentSC = styled.div`
    


`

export default MainContent