import React from 'react';
import styled from 'styled-components';

const ContactButton = () => {

    const email = "info@credon.eu"; // Replace with your email address
    const subject = "Question about academy"; // Optional subject
    const body = ""; // Optional email body     

    const rendering = () => {
        return (
            <ContactButtonSC>
                <a
                    href={`mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`}
                    style={{ textDecoration: "none", color : 'inherit' }}
                >
                    <p style={{cursor: "pointer" }}>
                        Contact
                    </p>
                </a>
            </ContactButtonSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ContactButtonSC = styled.div`
    
`

export default ContactButton